import 'jquery';

var $ = require("jquery");
var exported = false;

$('#export_button').click((event)=>{
  let target = $(event.target);
  target.attr("disabled", true);
  target.text("Exporting, Please Wait!")
  target.removeClass("btn-primary");
  target.addClass('btn-secondary');
  if(exported === false){
    exported = true;
    target.click();
  }else{
    event.preventDefault();
  }
})
